/* You can add global styles to this file, and also import other style files */
// @import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$aptieka-app-accent: mat-palette(
  (
    50: #fff3e0,
    100: #ffe0b2,
    200: #ffcc80,
    300: #ffb84d,
    400: #ffa726,
    500: #ff9800,
    600: #ff8c00,
    700: #f97c01,
    800: #f36c01,
    900: #ea5001,
    A100: #ffe0b2,
    A200: #ffcc80,
    A400: #ffa726,
    A700: #f97c01,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $light-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text,
    ),
  )
);
$aptieka-app-primary: mat-palette(
  (
    50: #fae9e9,
    100: #feccc2,
    200: #feac9b,
    300: #fe8b74,
    400: #ff7057,
    500: #ff573d,
    600: #f55139,
    700: #e74a34,
    800: #d8442f,
    900: #bf3726,
    A100: #feccc2,
    A200: #feac9b,
    A400: #ff7057,
    A700: #e74a34,
    contrast: (
      50: $dark-primary-text,
      100: $dark-primary-text,
      200: $dark-primary-text,
      300: $dark-primary-text,
      400: $dark-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $dark-primary-text,
      A200: $light-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text,
    ),
  )
);

// The warn palette is optional (defaults to red).
$aptieka-app-warn: mat-palette(
  (
    50: #7a0000,
    100: #7a0000,
    200: #7a0000,
    300: #7a0000,
    400: #7a0000,
    500: #7a0000,
    600: #7a0000,
    700: #7a0000,
    800: #7a0000,
    900: #7a0000,
    A100: #7a0000,
    A200: #7a0000,
    A400: #7a0000,
    A700: #7a0000,
    contrast: (
      50: $light-primary-text,
      100: $light-primary-text,
      200: $light-primary-text,
      300: $light-primary-text,
      400: $light-primary-text,
      500: $light-primary-text,
      600: $light-primary-text,
      700: $light-primary-text,
      800: $light-primary-text,
      900: $light-primary-text,
      A100: $light-primary-text,
      A200: $light-primary-text,
      A400: $light-primary-text,
      A700: $light-primary-text,
    ),
  )
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$aptieka-app-theme: mat-light-theme(
  (
    color: (
      primary: $aptieka-app-primary,
      accent: $aptieka-app-accent,
      warn: $aptieka-app-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($aptieka-app-theme);

body {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

:root {
  --app-padding: 16px;
  --container-width: min(1024px, 100vw);
  --color1: #fcf4ed;
  --color2: #e1bb8f;
  --color3: #b3a89b;
  --color4: #929497;
  --color5: #ffcb05;
  --color6: #d4422b;
}

.container {
  padding: var(--app-padding);
  max-width: 1024px;
  margin: 0 auto;
}

mat-progress-bar.mat-mdc-progress-bar {
  display: absolute;
}

.ordersList div.mdc-radio {
  display: none;
}

@media (max-width: 600px) {
  body {
    font-size: 14px;
  }
}
